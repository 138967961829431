.explorer-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding-right: 3vw;

  .explorer-form {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 40px;

    .address-input-outer {
      position: relative;
      display: inline-block;
      margin: 0;
      border: 0;
      padding: 0;
      border-radius: 0;
      width: calc(100% - 360px);
      height: 55px;
    }

    .address-input-inner {
      margin: 0;
      border: 1.5px solid map-get($bgColors, borderGrey);
      padding: 13px 0 13px 31px;
      border-radius: 10px;
      width: 100%;
      height: 55px;
    }

    .connect-button {
      width: 320px;
      height: 55px;
      margin-top: 0;
    }
  }

  .no-accounts-info {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
  }

  .autocomplete-items {
    position: absolute;
    border: 1px solid map-get($bgColors, borderGrey);
    border-bottom: none;
    border-top: none;
    z-index: 99;
    /*position the autocomplete items to be the same width as the container:*/
    top: 100%;
    left: 0;
    right: 0;
  }

  .autocomplete-items div {
    padding: 10px;
    cursor: pointer;
    background-color: map-get($bgColors, mainWhite);
    border-bottom: 1px solid map-get($bgColors, borderGrey);
  }

  .autocomplete-items div:hover {
    /*when hovering an item:*/
    background-color: map-get($bgColors, secondaryGrey);
  }
}
