@import '../../../styles/variables';

.outerContainer {
  display: flex;
  justify-content: center;
  align-items: center;

  .innerContainer {
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;

    .modalError {
      color: map-get($fontColors, errorColor);
    }

    .modalTitleContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 4px;

      .modalTitle {
        font-size: $fontBannerSmall;
        font-family: $boldFont;
      }

      .sourceType {
        font-size: $fontVerySmall;
        color: map-get($fontColors, mainGrey);
      }
    }

    .inputWithLabelContainer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 16px;

      .label {
        font-size: $fontLarge;
        font-family: $boldFont;
      }
    }
  }
}

.detailsRowContainer {
  display: flex;
  gap: 16px;
  padding: 10px;
  background: map-get($bgColors, secondaryGrey);

  .rowTitle {
    font-family: $boldFont;
  }

  .rowData {
    color: map-get($fontColors, mainGrey);
  }

  .deleteButton {
    width: 20px;
    height: 20px;
    color: map-get($fontColors, mainGrey);
    cursor: pointer;

    &:hover {
      color: map-get($fontColors, errorColor);
    }
  }
}