@import '../../styles/variables';


.container {
  width: 100%;

  .formContainer {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;

    .inputWithLabelContainer {
      display: flex;
      align-items: center;
      gap: 8px;

      .label {
        font-size: $fontLarge;
        font-family: $boldFont;
      }

      .wideSelect {
        width: 300px;
      }
    }

    .generateButtonContainer {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 16px;

      .generateButton {
        max-width: 300px;
      }
    }

    .emailText {
      white-space: pre-wrap;
      display: flex;
      flex-direction: column;
      gap: 16px;

      .emailHeading {
        font-size: $fontBannerSmall;
        font-family: $boldFont;
      }
    }
  }
}

.formControlsButton {
  padding: 15px 0 15px 0;
  width: 200px !important;
}

.sourcesTable {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.formControlsContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
