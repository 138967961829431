@import '../../styles/variables';
@import '../../components/risk-score/risk-score';

.customers {
  display: flex;
  flex-direction: column;
  gap: 40px;

  .customerBox {
    display: flex;
    padding: 26px 40px;
    flex-direction: column;
    align-items: flex-start;
    gap: 25px;
    border-radius: 12px;
    background: map-get($fontColors, mainWhite);
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.20);
    width: 100%;

    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 14px;
      color: map-get($dashboardLight, settingsBg);
      font-size: 25px;
      font-weight: $boldFont;

      .titleSection {
        display: flex;
        gap: 8px;
        align-items: center;

        .icon {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 12px;
          border-radius: 22px;
          background: map-get($bgColors, primaryWhite);
          color: map-get($fontColors, mainPurple);

          svg {
            width: 20px;
            height: 20px;
          }
        }

        .titleText {
          font-family: $boldFont;
        }

        .risk {
          display: flex;
          align-items: center;
          gap: 10px;
          color: map-get($fontColors, mainGrey);
          font-size: $fontLarge;
          font-family: $boldFont;
          margin-left: 20px;
        }
      }
    }

    .accountOverview {
      display: flex;
      flex-direction: column;
      gap: 25px;
      width: 100%;

      .tableHeader {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        text-transform: uppercase;
        color: map-get($fontColors, mainBlack);
        font-size: $fontVerySmall;
        font-family: $boldFont;
        letter-spacing: 1.68px;
        background: map-get($bgColors, secondaryWhite);
        padding: 6px 0 6px 0;
      }

      .tableHeaderAmlDemo {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        text-transform: uppercase;
        color: map-get($fontColors, mainBlack);
        font-size: $fontVerySmall;
        font-family: $boldFont;
        letter-spacing: 1.68px;
        background: map-get($bgColors, secondaryWhite);
        padding: 6px 0 6px 0;
      }

      .tableContent {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        color: map-get($fontColors, mainGrey);
        font-size: $fontMedium;
        font-family: $mediumFont;
        text-transform: capitalize;
      }

      .tableContentAmlDemo {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        color: map-get($fontColors, mainGrey);
        font-size: $fontMedium;
        font-family: $mediumFont;
        text-transform: capitalize;
      }
    }

    .connectedAccounts {
      display: flex;
      flex-direction: column;
      gap: 16px;
      width: 100%;

      .tableHeader {
        display: grid;
        grid-template-columns: repeat(6, 1fr) 20px;
        text-transform: uppercase;
        color: map-get($fontColors, mainBlack);
        font-size: $fontVerySmall;
        font-family: $boldFont;
        letter-spacing: 1.68px;
        background: map-get($bgColors, secondaryWhite);
        padding: 6px 0 6px 0;
      }

      .tableHeaderExpandable {
        grid-template-columns: 40px repeat(6, 1fr) 20px;
      }

      .tableHeaderAmlDemo {
        display: grid;
        grid-template-columns: repeat(4, 1fr) 20px;
        text-transform: uppercase;
        color: map-get($fontColors, mainBlack);
        font-size: $fontVerySmall;
        font-family: $boldFont;
        letter-spacing: 1.68px;
        background: map-get($bgColors, secondaryWhite);
        padding: 6px 0 6px 0;
      }

      .tableHeaderAmlDemoExpandable {
        grid-template-columns: 40px repeat(4, 1fr) 20px;
      }

      .tableContent {
        display: grid;
        grid-template-columns: repeat(6, 1fr) 20px;
        color: map-get($fontColors, mainGrey);
        font-size: $fontMedium;
        font-family: $mediumFont;
        text-transform: capitalize;
      }

      .tableContentExpandable {
        grid-template-columns: 40px repeat(6, 1fr) 20px;
      }

      .tableContentAmlDemo {
        display: grid;
        grid-template-columns: repeat(4, 1fr) 20px;
        color: map-get($fontColors, mainGrey);
        font-size: $fontMedium;
        font-family: $mediumFont;
        text-transform: capitalize;
      }

      .tableContentAmlDemoExpandable {
        grid-template-columns: 40px repeat(4, 1fr) 20px;
      }

      .verifiedIcon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        padding: 6.545px;
        border-radius: 12px;
        background: map-get($bgColors, primaryWhite);
        color: map-get($btnBgColors, primaryPurple);
      }

      .riskScore {
        display: flex;
        align-items: center;
        gap: 6px;

        .score {
          color: map-get($fontColors, mainGrey);
          font-size: $fontMedium;
          font-family: $mediumFont;
        }
      }

      .rowAndDividerContainer {
        display: flex;
        flex-direction: column;
        gap: 16px;
      }

      .disconnectIconContainer {
        width: 20px;
        height: 20px;
        color: map-get($fontColors, mainGrey);

        .disconnectIcon {
          cursor: pointer;
          color: map-get($legendColors, alertRed);
        }
      }
    }

    .riskOverview {
      display: flex;
      padding: 0 40px;
      width: 100%;
      justify-content: space-between;
      align-items: flex-start;

      .box {
        display: flex;
        flex-direction: column;
        gap: 8px;
        flex-shrink: 0;
        align-items: flex-start;
        width: 45%;

        .tableRow {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 8px 50px;
          width: 100%;

          .description {
            color: map-get($fontColors, mainBlack);
            font-size: $fontMedium;
            font-family: $boldFont;
          }

          .value {
            color: map-get($fontColors, mainGrey);
            font-size: $fontMedium;
            font-family: $mediumFont;
          }

          &:not(:last-child) {
            border-bottom: 1.5px solid #E9E9E9;;
          }
        }
      }
    }

    .consolidatedTransactions {
      display: flex;
      flex-direction: column;
      gap: 25px;
      width: 100%;

      .tableHeader {
        display: grid;
        grid-template-columns: 13% 10% 10% 25% 20% 10% 12%;
        color: map-get($fontColors, mainBlack);
        font-size: $fontVerySmall;
        font-family: $boldFont;
        letter-spacing: 1.68px;
        text-transform: uppercase;
        margin-top: 22px;
        background: map-get($bgColors, secondaryWhite);
        padding: 6px 0 6px 0;
      }

      .tableHeaderReduced {
        grid-template-columns: 20% 20% 20% 20% 20%;
        padding-inline: 22px;
      }

      .tableHeaderConcise {
        grid-template-columns: 40px 12% 17% 12% 20% 19% 17%;
      }

      .tableBody {
        display: flex;
        flex-direction: column;
        gap: 4px;

        .icon {
          max-width: 20px;
          max-height: 20px;
          display: flex;
          align-items: center;

          svg {
            width: 20px;
            height: 20px;
          }
        }

        .tableRow {
          display: grid;
          grid-template-columns: 13% 10% 10% 25% 20% 10% 12%;
          min-height: 76px;
          align-items: self-start;
          color: map-get($fontColors, mainGrey);
          font-size: 16px;
          font-weight: 500;
          border-radius: 12px;
          padding: 22px;

          .transactionDetails {
            display: flex;
            flex-direction: column;
            gap: 24px;

            .transaction {
              display: flex;
              flex-direction: column;
              height: 80px;

              .name {
                display: flex;
                gap: 2px;
                font-size: $fontMedium;
                font-family: $mediumFont;
                text-decoration-line: underline;
              }

              .banned {
                color: map-get($legendColors, alertRed);
              }

              .owned {
                color: map-get($legendColors, infoBlue);
              }

              .valueInfo {
                color: map-get($fontColors, mainBlack);
              }
            }
          }

          .txHash {
            display: flex;
            align-items: center;
            gap: 6px;
            font-size: $fontMedium;
            font-family: $mediumFont;
            text-decoration-line: underline;

            .icon {
              width: 20px;
              height: 20px;
            }
          }
        }

        .tableRowReduced {
          grid-template-columns: 20% 20% 20% 20% 20%;
        }

        .tableRowConcise {
          display: grid;
          grid-template-columns: 40px 12% 17% 12% 20% 19% 13% 4%;
          align-items: self-start;
          color: map-get($fontColors, mainGrey);
          font-size: $fontMedium;
          font-family: $mediumFont;
          border-radius: 12px;
          padding: 4px 0 4px 0;

          .tooltipCategoryList {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .halfWidth {
                width: 50%;
            }

            .tooltipCategoryValue{
              margin-top: 4px;
            }
          }

          .accountName {
            text-transform: capitalize;
          }

          .currencyDelta {

            .currencyDeltaValue {
                display: flex;
                gap: 8px;
                text-transform: uppercase;
            }

            .deltaValuePositive {
              color: map-get($legendColors, successGreen);
            }

            .deltaValueNegative {
              color: map-get($legendColors, alertRed);
            }
          }

          .transfer {
            display: flex;
            gap: 4px;

            .fromCurrencies {
              font-family: $regularFont;
              color: map-get($fontColors, mainBlack);
            }

            .toCurrencies {
              font-family: $regularFont;
              color: map-get($fontColors, mainBlack);
            }
          }
        }

        .withdrawalRow {
          background: map-get($bgColors, withdrawalHighlight);
          color: map-get($fontColors, withdrawalText);
        }

        .depositRow {
          background: map-get($bgColors, depositHighlight);
          color: map-get($fontColors, depositText);
        }

        .rowConcise {
          padding-inline: 10px;
          color: map-get($fontColors, mainGrey);
        }

        .tableRisk {
          display: flex;
        }

        .infoMessageContainer {
          display: flex;
          justify-content: center;
          align-items: center;

          .infoMessage {
            font-family: $boldFont;
            font-size: $fontBilling;
            color: map-get($fontColors, mainGrey);
          }
        }
      }
    }
  }

  .disableShadows {
    box-shadow: none;
    padding: 0;
  }
}

.expandedSubrowContainer {
  display: grid;
  grid-template-columns: auto 1fr 64px;

  .verticalLine {
    height: calc(100% - 16px);
    width: 1px;
    background: map-get($fontColors, mainGrey);
    margin-left: 20px;
    align-self: stretch;

  }

  .expandedSubrowContentContainer {
    display: flex;
    justify-content: right;

    .expandedSubrow {
      width: calc(100% - 40px);
    }
  }
}

.loadMoreContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: $boldFont;

  .loadMoreText {
    cursor: pointer;
    color: map-get($fontColors, mainGrey);

    &:hover {
      text-decoration: underline;
      color: map-get($fontColors, mainPurple);
    }
  }

  .loadingText {
    color: map-get($fontColors, mainGrey);

    .loaderWithTextContainer {
      display: flex;
      align-items: center;
      gap: 4px;
    }
  }
}

.address {
  display: flex;
  align-items: center;
  gap: 2px;
  color: map-get($fontColors, mainPurple);
  font-size: $fontMedium;
  font-family: $mediumFont;
  text-decoration-line: underline;
}

.wide {
  width: 100%;
}

.legend {
  display: flex;
  flex: 1;
  justify-content: right;
  color: #266FF8;
  font-family: $mediumFont;
  font-size: $fontMedium;
  gap: 24px;
  margin-right: 26px;

  .legendEntryContainer {
    display: flex;
    align-items: center;
    gap: 10px;

    .legendText {
      color: map-get($fontColors, mainGrey);
    }
  }

  .info {
    color: map-get($legendColors, infoBlue);
  }

  .alert {
    color: map-get($legendColors, alertRed);
  }

  .success {
    color: map-get($legendColors, successGreen);
  }

  .withdrawalDeposit {
    color: map-get($legendColors, withdrawalDeposit);
  }

  .bullet {
    width: 10px;
    height: 10px;
    border-radius: 10px;
    background: currentColor;
  }
}

.labeledAddressContainer {
  display: flex;
  gap: 10px;
}

.oneline {
  max-height: 20px;
  text-decoration: none;
}

.txTypeContainer {
  display: flex;
  gap: 4px;
  text-transform: capitalize;
}

.expander {
  display: flex;
  gap: 6px;
  cursor: pointer;
  color: map-get($fontColors, mainGrey);

  .expanderDash {
    width: 14px;
    color: map-get($fontColors, mainPurple);
  }

  .dashExpanded {
    transform: rotate(90deg);
  }

  .dashNotExpanded {
    transform: rotate(270deg);
  }

  .expanderText {
    text-decoration: underline;
    color: map-get($fontColors, mainPurple);
  }
}

.warningIcon {
  display: flex;
  align-items: center;
  justify-content: center;

  .warningIconSmall {
    width: 20px;
    height: 20px;
  }
}

.smallCopyIcon {
  width: 20px;
  height: 20px;

  svg {
    width: 20px !important;
    height: 20px !important;
  }
}

.capitalized {
  text-transform: capitalize;
}

.copyChildrenContainer {
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
}

.assetsBreakdown {
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;

  .assetsBreakdownTableHeader {
    display: grid;
    grid-template-columns: 22% 22% 22% 22% 12%;
    text-transform: uppercase;
    color: map-get($fontColors, mainBlack);
    font-size: $fontVerySmall;
    font-family: $boldFont;
    letter-spacing: 1.68px;
    background: map-get($bgColors, secondaryWhite);
    padding: 6px 16px 6px 16px;
    border-radius: 24px;
  }

  .assetsBreakdownTableContent {
    display: grid;
    grid-template-columns: 22% 22% 22% 22% 12%;
    color: map-get($fontColors, mainGrey);
    font-size: $fontMedium;
    font-family: $mediumFont;
    text-transform: capitalize;
    padding-inline: 16px;

    .value {
      color: map-get($fontColors, mainBlack);
    }
  }

  .assetsBreakdownRowAndDividerContainer {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
}

.iconWithSymbolContainer {
  display: flex;
  gap: 8px;
  align-items: center;

  .imgIconContainer {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 24px;
    }
  }
}

.centered {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;

  .wideCentered {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
}

.centeredBothWays {
  align-items: center;
}

.clickable {
  cursor: pointer;

  &:hover {
    color: map-get($fontColors, mainPurple);
  }
}

.smallIcon {
  width: 20px;
  height: 20px;

  svg {
    width: 20px;
    height: 20px;
  }
}

.expandedAssetInfoContainer {
  margin: 20px 0 20px 0;
}

.hug {
  display: inline-block;
}

.imgIcon {
  width: 24px;
}

.rowDivider {
  width: 100%;
  height: 0;
  border-bottom: 1.5px solid map-get($bgColors, secondaryGrey);
  margin-top: 4px;
}

.rowDividerShort {
  border-bottom: 1px solid map-get($bgColors, secondaryGrey);
}

.iconWithText {
  display: flex;
  gap: 4px;
  align-items: center;

  .iconContainer {
    width: 30px;
    height: 30px;

    img {
      width: 100%;
      height: 100%;
    }
  }
}
